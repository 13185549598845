<template>
  <NuxtPwaManifest />
  <div class="text-center m-2">
    <!-- <Navbar /> -->

    <slot />
  </div>
</template>

<style>
/* fix to make this work with smaller screens */
.p-toast {
  max-width: calc(100vw - 40px);
}
</style>
